import ApiService from "@/core/services/api.service";


/**
 * Service to call HTTP request via Axios
 */
const BaseService = {

    // config
    makeToast(variant = null, mes, that) {
        that.$root.$bvToast.toast(mes, {
            title: `Thông báo`,
            variant: variant,
            solid: true
        })
    },
    getMenu() {
        return ApiService.get("menu");
    },
    getCountry() {
        return ApiService.get("d/country");
    },
    getCity() {
        return ApiService.get("d/province");
    },
    getHotelType() {
        return ApiService.get("d/hotel-type");
    },
    getDistrict(_params) {
        return ApiService.query("d/district", _params);
    },
    getWard(_params) {
        return ApiService.query("d/ward", _params);
    },
    getFloor() {
        return ApiService.get("d/floor");
    },
    getBedType() {
        return ApiService.get("d/bed-type");
    },
    getRoomType() {
        return ApiService.get("d/room-type");
    },
    getLevelAgency() {
        return ApiService.get("d/agency-level");
    },
    getPriceType() {
        return ApiService.get("d/price-type");
    },

    roomAutocompleted(_params) {
        return ApiService.query("room-autocompleted", _params);
    },
    getCurrencySource() {
        return ApiService.get("d/currency-source");
    },
    getCalendarBooking(_params) {
        return ApiService.query("calendar-booking",_params);
    },
    getBookingSource() {
        return ApiService.get("d/booking-source");
    },
    getBookingService(_params) {
        return ApiService.query("d/booking-service", _params);
    },
    getBookingObject(_params) {
        return ApiService.query('d/booking-object', _params)
    },
    getDashboardStats() {
        return ApiService.get("dashboard/stats");
    },
};

export default BaseService;
