// action types

// mutation types

const state = {
    activeShow: false
};

const getters = {
    activeShowModalStaff: state => {
        console.log('state.status',state.activeShow)
        return state.activeShow;
    }
};

const actions = {

};

const mutations = {
    SET_STATUS_SHOW_MODAL_STAFF: (state, payload) => {
        state.activeShow = payload;
        console.log(state.activeShow)
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
