import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        console.log('process.env.BASE_URL', process.env.VUE_APP_BASE_URL)
        Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
        // Vue.axios.defaults.baseURL = "http://localhost";
        // Vue.axios.defaults.headers = {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // };
    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
        console.log('JwtService.getToken()', JwtService.getToken())
        if (JwtService.getToken()) {
            Vue.axios.defaults.headers[
                "Authorization"
                ] = `Bearer ${JwtService.getToken()}`;
        }
    },

    query(resource, params) {
        // this.setHeader();
        return Vue.axios.get(resource, {params: params}).catch(error => {
            // console.log(error);
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
    get(resource, slug = "") {
        // this.setHeader();
        return Vue.axios.get(`${resource}/${slug}`).catch(error => {
            // console.log(error);
            throw new Error(`[KT] ApiService ${error}`);
        });
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
        // this.setHeader();
        return Vue.axios.post(`${resource}`, params);
    },

    upload(resource, params) {
        // this.setHeader();
        return Vue.axios.post(`${resource}`, params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        // this.setHeader();
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        // this.setHeader();
        return Vue.axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        // this.setHeader();
        return Vue.axios.delete(resource).catch(error => {
            // console.log(error);
            throw new Error(`[RWV] ApiService ${error}`);
        });
    }
};

export default ApiService;
