// action types
// mutation types
import BaseService from "@/core/helper/base.service";

export const FETCH_MENU = "fetchMenu"

const state = {
    menu: null
};

const getters = {
    getterMenu: state => {
        console.log('state.status', state.menu)
        return state.menu;
    }
};

const actions = {
    [FETCH_MENU](state, payload) {
        state.commit(FETCH_MENU, payload);
    },
};

const mutations = {
    setMenu: (state, payload) => {
        state.menu = payload;
        console.log(state.menu)
    },
    [FETCH_MENU](state) {
        BaseService.getMenu().then((res) => {
            let menu = res.data.data.items
            let arr = []
            menu.filter((item) => {
                // console.log(typeof item.path, item.path)
                if (item.path === "/setting") {
                    item.sub_menu.filter((item2) => {
                        arr.push(item2.path)
                    })
                } else {
                    arr.push(item.path)
                }
            })
            state.menu = arr
            console.log(state.menu)
        }).catch((e) => {
            console.log(e)
        })
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
