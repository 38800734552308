// action types

// mutation types

export const INVOICE_PRINT_DATA = "invoicePrintData";
export const INVOICE_SHOW_VAT = "invoiceShowData";

const state = {
    invoicePrintData: {},
    showVat: false
};

const getters = {
    // showDetailsReservetion: state => {
    //     return state.detailData;
    // }
};

const actions = {

};

const mutations = {
    [INVOICE_PRINT_DATA](state, items) {
        state.invoicePrintData = items;
    },
    [INVOICE_SHOW_VAT](state, status) {
        state.showVat = status;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
