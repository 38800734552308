import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import {RESET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
import ClipboardJS from "clipboard";
// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import JwtService from "@/core/services/jwt.service";
import 'ant-design-vue/dist/antd.css';
import Antd from 'ant-design-vue';
import {FETCH_MENU} from "@/core/services/store/menuPath/menuPath.module";
import BaseService from "@/core/helper/base.service";



Vue.use(Antd);
Vue.config.productionTip = false;

window.PerfectScrollbar = PerfectScrollbar;
window.ClipboardJS = ClipboardJS;

// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();
JwtService.getToken()
const token = JwtService.getToken('id_token');
if (token) {
    Vue.axios.defaults.headers[
        "Authorization"
        ] = `Bearer ${JwtService.getToken()}`;
    // Vue.axios.defaults.headers.common['Authorization'] = token
}
// else{
//   this.$router.push({name: "login"})
// }



router.beforeEach((to, from, next) => {
    // if (store.state.menu.menu === null) {
    //     BaseService.getMenu().then((res) => {
    //         let menu = res.data.data.items
    //         let arr = []
    //         menu.filter((item) => {
    //             // console.log(typeof item.path, item.path)
    //             if (item.path === "/setting") {
    //                 item.sub_menu.filter((item2) => {
    //                     arr.push(item2.path)
    //                 })
    //             } else {
    //                 arr.push(item.path)
    //             }
    //         })
    //         store.commit('setMenu',arr)
    //         if (arr.includes(to.path)) {
    //             next()
    //         } else {
    //             router.push({name: 'emptyRoom'}).then(r => r)
    //         }
    //     }).catch((e) => {
    //         console.log(e)
    //     })
    // } else {
    //     if (store.state.menu.menu.includes(to.path)) {
    //         next()
    //     } else {
    //         router.push({name: 'emptyRoom'}).then(r => r)
    //     }
    // }



    // Ensure we checked auth before each page load.
    // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
    // let token = window.localStorage.getItem('id_token')
    // if (token) {
    //   console.log(111);
    //   next()
    // }else if (!store.getters.isAuthenticated) {
    //   console.log(222);
    //   next()
    //
    //   // store.dispatch(types.LOGIN).then(() => next())
    // } else {
    //   console.log(333);
    //
    //   next()
    // }
    next()

    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount("#app");
