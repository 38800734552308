import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import diagram from "./diagram/diagram.module";
import detailsReservetion from "./detailsReservetion/detailsReservetion.module";
import invoicePrint from "./invoicePrint/invoicePrint.module";
import sellTheBill from "./seeTheBill/sellTheBill.module";
import staff from "./staff/staff.module";
import menu from "./menuPath/menuPath.module"
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    diagram,
    detailsReservetion,
    invoicePrint,
    sellTheBill,
    staff,
    menu
  }
});
