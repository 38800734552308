// action types

// mutation types

export const SHOW_BTN_PRINT = "showBtnPrint";

const state = {
    showBtnPrint: true,
};

const getters = {
    // showDetailsReservetion: state => {
    //     return state.detailData;
    // }
};

const actions = {

};

const mutations = {
    [SHOW_BTN_PRINT](state, items) {
        state.showBtnPrint = items;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
