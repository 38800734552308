// action types

// mutation types

import ApiService from "@/core/services/api.service";
import {REGISTER, SET_AUTH, SET_ERROR} from "@/core/services/store/auth.module";
import BaseService from "@/core/helper/base.service";
export const FETCHAPI = "detailsReservetion";
export const SET_DETAIL_DATA = "setDetailData";
export const LINK_BOOKING_GROUP = "linkBookingGroup";
export const SHOW_LINK_ID = "showLinkId";

const state = {
    detailData: {},
    linkBooking: '',
    showLinkId: '',
};

const getters = {
    // showDetailsReservetion: state => {
    //     return state.detailData;
    // }
};

const actions = {
    [FETCHAPI](context, payload) {
        return new Promise(resolve => {
            ApiService.get(`/booking/${payload}`).then((response) => {
                if (response.data.code === 1) {
                    context.commit(SET_DETAIL_DATA, response.data.data);
                    resolve(response);
                } else {
                    BaseService.makeToast('danger', response.data.message, this)
                }
            }).catch(({err}) => {
                console.log(err)
                // BaseService.makeToast('danger', err.response.data.message, this)
            });

        });
    },
};

const mutations = {
    [SET_DETAIL_DATA](state, items) {
        state.detailData = items;
    },
    [LINK_BOOKING_GROUP](state, items) {
        state.linkBooking = items;
    },
    [SHOW_LINK_ID](state, items) {
        state.showLinkId = items;
    },

};

export default {
    state,
    actions,
    mutations,
    getters
};
