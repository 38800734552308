// action types

// mutation types

const state = {
    status: true,
    dateDiagram: '',
    showBookRoom: false,
    showAddRoomkkkk: false,
    checkboxEmptyRoom: [],
};

const getters = {
    statusAddRoom: state => {
        console.log(state.status)
        return state.status;
    }
};

const actions = {

};

const mutations = {
    SET_STATUS_ADD_ROOM: (state, payload) => {
        state.status = payload;
        console.log(state.status)
    },
    SET_DATE_DIAGRAM: (state, payload) => {
        state.dateDiagram = payload;
    },
    SHOW_BOX_ROOM: (state, payload) => {
        state.showBookRoom = payload;
    },
    SHOW_ADD_ROOM: (state, payload) => {
        state.showAddRoomkkkk = payload;
    },
    CHECKBOX_EMPTY_ROOM:(state, payload) => {
        state.checkboxEmptyRoom = payload;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
