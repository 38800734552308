import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            redirect: "/dashboard",
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "/dashboard",
                    name: 'dashboard',
                    component: () => import("@/view/pages/Dashboard.vue")
                },

                {
                    path: "/profile",
                    component: () => import("@/view/pages/custom_pages/Profile.vue")
                },
                {
                    path: "/test",
                    component: () => import("@/view/pages/share/test/test")
                },
                {
                    path: "/managementAgent",
                    component: () => import("@/view/pages/agent/index.vue"),
                    children: [
                        {
                            path: "",
                            name: 'agent',
                            component: () => import("@/view/pages/agent/agent.vue")
                        },
                    ]
                },
            ]
        },
        {
            path: "/",
            component: () => import("@/view/pages/auth/login_pages/Login-1"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("@/view/pages/auth/login_pages/Login-1")
                },
                {
                    name: "register",
                    path: "/register",
                    component: () => import("@/view/pages/auth/login_pages/Login-1")
                },

            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue")
        },

    ]
});
